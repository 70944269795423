<template>
  <b-container>
    <b-row>
      <b-col
        sm="12"
        md="12"
        lg="8"
        xl="6"
      >
        <b-card>
          <validation-observer ref="formValidation">
            <b-form @submit.prevent="validateForm()">
              <b-row>
                <b-col>
                  <h1 class="font-weight-bolder">
                    Alta de Producto
                  </h1>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group :label="$t('productName')">
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <b-form-input
                        v-model="product_catalog.name"
                        size="lg"
                        class="font-weight-bolder"
                        autocomplete="off"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  lg="6"
                >
                  <b-form-group :label="$t('SKU')">
                    <validation-provider
                      #default="{ errors }"
                      name="SKU"
                      rules="required"
                    >
                      <b-form-input
                        v-model="product_catalog.sku"
                        size="lg"
                        class="font-weight-bolder"
                        autocomplete="off"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  lg="6"
                >
                  <b-form-group :label="$t('category')">
                    <validation-provider
                      #default="{ errors }"
                      name="Categoría"
                      rules="required"
                    >
                      <b-form-select
                        v-model="product_catalog.category_id"
                        :options="categories"
                        text-field="name"
                        value-field="id"
                        size="lg"
                        class="font-weight-bolder"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  lg="6"
                >
                  <b-form-group :label="$t('brand')">
                    <validation-provider
                      #default="{ errors }"
                      name="Marca"
                    >
                      <b-form-input
                        v-model="product_catalog.brand"
                        size="lg"
                        class="font-weight-bolder"
                        autocomplete="off"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  lg="6"
                >
                  <b-form-group :label="$t('units')">
                    <validation-provider
                      #default="{ errors }"
                      name="unidades"
                      rules="required"
                    >
                      <b-form-select
                        v-model="product_catalog.unit_type"
                        :options="unitTypeOptions"
                        size="lg"
                        class="font-weight-bolder"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group :label="$t('description')">
                    <b-form-textarea
                      v-model="product_catalog.description"
                      no-resize
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="border-secondary p-2 m-0 mt-1 custom-border">
                <!-- <b-col sm="12">
                  <h2>{{ $t('productImage') }}</h2>
                  <b-row class="align-items-center">
                    <b-col
                      v-if="product_catalog.logo"
                      sm="12"
                      lg="3"
                    >
                      <b-img
                        v-if="previewImg"
                        fluid
                        :src="previewImg"
                      />
                      <b-img
                        v-else
                        fluid
                        :src="product_catalog.logo"
                      />
                    </b-col>
                    <b-col
                      sm="12"
                      lg="9"
                    >
                      <span class="text-muted">{{ $t('resolutionInfo') }}</span>
                      <div
                        v-if="productImage"
                        class="mt-1"
                      >
                        <span class="text-primary mr-1">
                          {{ productImage.name }}
                        </span>
                        <span class="text-primary">
                          {{ productImage.size | prettyBytes }}
                        </span>
                      </div>
                      <b-row class="mt-1">
                        <b-col sm="12">
                          <b-form-file
                            v-model="productImage"
                            accept="image/*"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            @input="createImage"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col> -->
                <b-col sm="12">
                  <h2>{{ $t('productImage') }}</h2>
                  <div class="d-flex">
                    <base-cropper
                      :model="product_catalog"
                      @cropped-image="product_catalog.logo = $event"
                    />
                    <span class="text-muted ml-1">{{
                      $t('resolutionInfo')
                    }}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="justify-content-end mt-2">
                <b-col>
                  <b-button
                    variant="primary"
                    size="lg"
                    class="px-5"
                    type="submit"
                  >
                    {{ $t('save') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        class="d-flex align-items-center justify-content-center"
        lg="4"
        xl="6"
      >
        <b-img
          :src="require('@/assets/images/pages/forgot-password-v2.svg')"
          width="500px"
          fluid
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BaseCropper from '@/components/BaseCropper.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseCropper,
  },
  data() {
    return {
      product_catalog: {
        name: '',
        category_id: '',
        sku: '',
        description: '',
        logo: null,
      },
      // eslint-disable-next-line
      productImage: null,
      // eslint-disable-next-line
      previewImg: null,
      required,
      unitTypeOptions: [
        {
          value: 'meter',
          text: this.$t('meter'),
        },
        {
          value: 'square_meters',
          text: this.$t('squareMeters'),
        },
        {
          value: 'cubic_meters',
          text: this.$t('cubicMeters'),
        },
        {
          value: 'piece',
          text: this.$t('piece'),
        },
        {
          value: 'kilogram',
          text: this.$t('kilogram'),
        },
        {
          value: 'ton',
          text: this.$t('ton'),
        },
        {
          value: 'bundle',
          text: this.$t('bundle'),
        },
        {
          value: 'box',
          text: this.$t('box'),
        },
        {
          value: 'trip',
          text: this.$t('trip'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('categories', ['categories']),
  },
  created() {
    this.fetchCategories()
    this.fetchProduct({ productId: this.$route.params.productId }).then(
      response => {
        this.product_catalog = response
      },
    )
  },
  methods: {
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('products', ['editProduct', 'fetchProduct']),

    validateForm() {
      this.$refs.formValidation.validate().then(success => {
        if (success) {
          this.editProduct(this.product_catalog).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('productCreated'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'productsCatalogs' })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-border {
  border-radius: 4px;
}
</style>
