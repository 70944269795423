<template>
  <div>
    <div class="d-flex">
      <b-img
        v-if="cropped"
        :width="width"
        class="cursor-pointer soft-border"
        rounded
        :src="cropped"
        @click="chooseFile()"
      />
      <b-img
        v-else
        key="img2"
        :width="width"
        class="cursor-pointer soft-border"
        rounded
        :src="
          model.logo != null
            ? model.logo
            : require('@/assets/images/logo/logo_mono.svg')
        "
        @click="chooseFile()"
      />
    </div>
    <b-form-file
      :id="`fileInput-${index}`"
      class="d-none"
      accept="image/*"
      @change="croppie"
    />
    <b-modal
      :id="`croppieModal-${index}`"
      ok-only
      @ok="handleEmit()"
    >
      <vue-croppie
        :ref="`cropRef-${index}`"
        :enable-resize="false"
        :enable-orientation="false"
        :mouse-wheel-zoom="true"
        :boundary="boundary"
        :viewport="viewport"
        @result="result"
        @update="crop"
      />
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default() {
        return 120
      },
    },
    boundary: {
      type: Object,
      default() {
        return { width: 300, height: 300 }
      },
    },
    viewport: {
      type: Object,
      default() {
        return { width: 150, height: 150, type: 'square' }
      },
    },
    index: {
      type: String,
      default() {
        return Date.now().toString()
      },
    },
  },
  data() {
    return {
      cropped: null,
    }
  },
  methods: {
    croppie(e) {
      this.$bvModal.show(`croppieModal-${this.index}`)
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const reader = new FileReader()
      reader.onload = el => {
        this.$refs[`cropRef-${this.index}`].bind({
          url: el.target.result,
        })
      }
      reader.readAsDataURL(files[0])
    },
    crop() {
      const options = {
        format: 'png',
        square: true,
      }
      this.$refs[`cropRef-${this.index}`].result(options, output => {
        const newLogo = {
          data: null,
        }
        this.model.logo = newLogo
        this.cropped = output
        this.model.logo.data = output
      })
    },

    result(output) {
      this.cropped = output
    },
    chooseFile() {
      document.getElementById(`fileInput-${this.index}`).click()
    },
    handleEmit() {
      let cropCopy

      if (!this.cropped) {
        cropCopy = {
          data: null,
        }
      } else {
        cropCopy = {
          data: this.cropped,
        }
      }
      this.$emit('croppedImage', cropCopy)
    },
    imgToDisplay() {},
  },
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.soft-border {
  border: 4px solid rgba(122, 122, 122, 0.1);
}
</style>
