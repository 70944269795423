var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"8","xl":"6"}},[_c('b-card',[_c('validation-observer',{ref:"formValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"font-weight-bolder"},[_vm._v(" Alta de Producto ")])])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('productName')}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.product_catalog.name),callback:function ($$v) {_vm.$set(_vm.product_catalog, "name", $$v)},expression:"product_catalog.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('SKU')}},[_c('validation-provider',{attrs:{"name":"SKU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.product_catalog.sku),callback:function ($$v) {_vm.$set(_vm.product_catalog, "sku", $$v)},expression:"product_catalog.sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('category')}},[_c('validation-provider',{attrs:{"name":"Categoría","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"font-weight-bolder",attrs:{"options":_vm.categories,"text-field":"name","value-field":"id","size":"lg"},model:{value:(_vm.product_catalog.category_id),callback:function ($$v) {_vm.$set(_vm.product_catalog, "category_id", $$v)},expression:"product_catalog.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('brand')}},[_c('validation-provider',{attrs:{"name":"Marca"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.product_catalog.brand),callback:function ($$v) {_vm.$set(_vm.product_catalog, "brand", $$v)},expression:"product_catalog.brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('units')}},[_c('validation-provider',{attrs:{"name":"unidades","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"font-weight-bolder",attrs:{"options":_vm.unitTypeOptions,"size":"lg"},model:{value:(_vm.product_catalog.unit_type),callback:function ($$v) {_vm.$set(_vm.product_catalog, "unit_type", $$v)},expression:"product_catalog.unit_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('description')}},[_c('b-form-textarea',{attrs:{"no-resize":""},model:{value:(_vm.product_catalog.description),callback:function ($$v) {_vm.$set(_vm.product_catalog, "description", $$v)},expression:"product_catalog.description"}})],1)],1)],1),_c('b-row',{staticClass:"border-secondary p-2 m-0 mt-1 custom-border"},[_c('b-col',{attrs:{"sm":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('productImage')))]),_c('div',{staticClass:"d-flex"},[_c('base-cropper',{attrs:{"model":_vm.product_catalog},on:{"cropped-image":function($event){_vm.product_catalog.logo = $event}}}),_c('span',{staticClass:"text-muted ml-1"},[_vm._v(_vm._s(_vm.$t('resolutionInfo')))])],1)])],1),_c('b-row',{staticClass:"justify-content-end mt-2"},[_c('b-col',[_c('b-button',{staticClass:"px-5",attrs:{"variant":"primary","size":"lg","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1)],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"lg":"4","xl":"6"}},[_c('b-img',{attrs:{"src":require('@/assets/images/pages/forgot-password-v2.svg'),"width":"500px","fluid":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }